import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ForYou from '../views/ForYou.vue'
import Secret from '../views/Secret.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/foryou',
    name: 'foryou',
    component: ForYou
  },
  {
    path: '/secret',
    name: 'secret',
    component: Secret
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
  },
  {
    path: '/settings',
    // name: 'settings',
    // component: () => import(/* webpackChunkName: "settings" */ '../views/Setting/IndexView.vue'),
    children: [
      {
        path: '',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Setting/Index.vue'),
      },
      {
        path: 'account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '../views/Setting/Account.vue'),
      }
    ],
  },
  {
    path: '/user',
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/User/Profile.vue'),
      },
      {
        path: 'fans',
        name: 'fans',
        component: () => import(/* webpackChunkName: "fans" */ '../views/User/Fans.vue'),
      },
      {
        path: 'follow',
        name: 'follow',
        component: () => import(/* webpackChunkName: "follow" */ '../views/User/Follow.vue'),
      },
    ],
  },
  {
    path: '/tweet',
    children: [
      {
        path: 'detail',
        name: 'detail',
        component: () => import(/* webpackChunkName: "detail" */ '../views/Tweet/Detail.vue'),
      },
    ],
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
