<template>
  <div class="card tweet-cell w-100 shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3" @click.self="goDetail(tweet?.TwAddress)">
    <div class="card-body p-0 d-flex" @click.self="goDetail(tweet?.TwAddress)">

      <router-link :to="'/user/profile?uid=' + tweet?.UserInfo?.UserAddress" class="avatar me-3">
        <Avatar :size="45" :avatar="tweet?.UserInfo?.Avatar" />
      </router-link>
      <h4 class="fw-700 font-xssss mt-1">
        <router-link class="text-grey-900" :to="'/user/profile?uid=' + tweet?.UserInfo?.UserAddress">{{
            tweet?.UserInfo?.Nickname
        }}</router-link>
        <router-link :to="'/tweet/detail?id=' + tweet?.TwAddress">
          <span class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{ timestampToTime(tweet?.CreatedAt)
          }}</span>
        </router-link>
      </h4>

    </div>
    <div class="card-body p-0 me-lg-5">
      <p class="fw-500 text-content cursor-pointer lh-26 font-xssss w-100"><span
          @click.self="goDetail(tweet?.TwAddress)" v-html="formatContent(tweet?.Content)"></span></p>
    </div>

    <div v-if="tweet?.OriginTweet" class="card-body border-top-xs pt-4 pb-3 pe-4 d-block ps-5 ms-1 position-relative"
      @click.self="goDetail(tweet.OriginTweet.TwAddress)">
      <router-link :to="'/user/profile?uid=' + tweet.OriginTweet?.UserInfo?.UserAddress"
        class="avatar position-absolute left-0 ms-2 mt-1">
        <Avatar :size="35" :avatar="tweet?.OriginTweet?.UserInfo?.Avatar" />
      </router-link>
      <div class="chat p-3 bg-greylight rounded-xxl d-block text-left theme-dark-bg"
        @click.self="goDetail(tweet.OriginTweet.TwAddress)">

        <h4 class="fw-700  font-xssss mt-0 mb-1">
          <router-link class="text-grey-900" :to="'/user/profile?uid=' + tweet.OriginTweet?.UserInfo?.UserAddress">
            {{ tweet?.OriginTweet?.UserInfo?.Nickname }}
          </router-link>
          <router-link :to="'/tweet/detail?id=' + tweet.OriginTweet.TwAddress">
            <span class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{
                timestampToTime(tweet?.OriginTweet?.CreatedAt)
            }}</span>
          </router-link>
        </h4>

        <p class="fw-500 text-grey-500 cursor-pointer lh-20 font-xssss w-100 mt-2 mb-0">
          <span @click.self="goDetail(tweet.OriginTweet.TwAddress)"
            v-html="formatContent(tweet?.OriginTweet?.Content)"></span>
        </p>
        <div v-if="tweet?.OriginTweet?.Attachment" class="card-body d-block p-0">
          <div class="row ps-2 pe-2" @click.self="goDetail(tweet.OriginTweet.TwAddress)">
            <div v-for="(img, i) in images(tweet.OriginTweet.Attachment)" :key="i" class="col-xs-4 col-sm-4 p-1">
              <Image :src="img" :list="images(tweet.OriginTweet.Attachment)" :index="i" />
            </div>
          </div>

          <div class="font-xssss">
            <div v-for="(file, i) in filterAttachment(tweet.OriginTweet.Attachment, 'file')" :key="i">
              <a :href="file.url" target="_blank">{{ file.name }}</a>
            </div>
          </div>

          <div class="player bg-transparent shadow-none">
            <audio v-for="(file, i) in filterAttachment(tweet.OriginTweet.Attachment, 'audio')" :key="i" preload="auto"
              controls>
              <source :src="file.url" :type="file.mime">
              Your browser does not support the audio element.
            </audio>
          </div>

          <div class="player bg-transparent shadow-none">
            <video v-for="(file, i) in filterAttachment(tweet.OriginTweet.Attachment, 'video')" :key="i" controls
              preload="auto" data-setup="{}">
              <source :src="file.url" :type="file.mime" />
              <p class="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a
                web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
              </p>
            </video>
          </div>

        </div>
      </div>
    </div>

    <div v-if="tweet?.Attachment" class="card-body d-block p-0">
      <div class="row ps-2 pe-2" @click.self="goDetail(tweet?.TwAddress)">
        <div v-for="(img, i) in images(tweet.Attachment)" :key="i" class="col-xs-4 col-sm-4 p-1">
          <Image :src="img" :list="images(tweet.Attachment)" :index="i" />
        </div>
      </div>

      <div class="font-xss">
        <div v-for="(file, i) in filterAttachment(tweet.Attachment, 'file')" :key="i">
          <a :href="file.url" target="_blank">{{ file.name }}</a>
        </div>
      </div>

      <div class="player bg-transparent shadow-none">
        <audio v-for="(file, i) in filterAttachment(tweet.Attachment, 'audio')" :key="i" preload="auto" controls>
          <source :src="file.url" :type="file.mime">
          Your browser does not support the audio element.
        </audio>
      </div>

      <div class="player bg-transparent shadow-none">
        <video v-for="(file, i) in filterAttachment(tweet.Attachment, 'video')" :key="i" controls preload="auto"
          data-setup="{}">
          <source :src="file.url" :type="file.mime" />
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
          </p>
        </video>
      </div>

    </div>
    <div class="card-body d-flex p-0 mt-3" @click.self="goDetail(tweet?.TwAddress)">
      <div title="Like"
        class="emoji-bttn d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">

        <el-button type="danger" class="me-1" :loading="liking" @click="handleTweetLike" :circle="!liking"
          :round="liking" size="small">
          <i class="feather-heart  font-xss"></i>
        </el-button> {{ likeCount }}
      </div>

      <div title="Comment" class="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
        <el-button type="warning" class="me-1" circle @click="comment = !comment; forward = false" size="small">
          <i class="feather-message-circle font-xss"></i>
        </el-button>
        <span class="d-none-xss">{{ commentCount }}</span>
      </div>

      <div title="Forward" class="d-flex align-items-center fw-600 text-grey-900 text-dark  font-xssss">
        <el-button color="#05f" class="me-1" circle @click="forward = !forward; comment = false" size="small">
          <i class="feather-external-link font-xss"></i>
        </el-button>
        <span class="d-none-xss">{{ forwardCount }}</span>
      </div>
    </div>

    <!-- post forward -->
    <div v-if="forward">
      <div class="card-body p-0 mt-3 position-relative">
        <figure class="avatar position-absolute ms-2 mt-1 top-5">
          <Avatar :size="28" :avatar="user?.Avatar" />
        </figure>
        <textarea name="message"
          class="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-700 fw-500 border-light-md theme-dark-bg"
          cols="30" rows="10" v-model="forwardContent" placeholder="What's on your mind?"></textarea>
      </div>

      <div class="card-body p-0">
        <el-button type="primary" color="#05f" :loading="forwarding" plain @click="createForward" round>
          <i class=" font-xs feather-external-link me-2 "></i>Forward
        </el-button>
      </div>
    </div>
    <!-- post forward -->


    <div v-if="comment">
      <div class="card-body p-0 mt-3 position-relative">
        <figure class="avatar position-absolute ms-2 mt-1 top-5">
          <Avatar :size="28" :avatar="user?.Avatar" />
        </figure>
        <textarea name="message"
          class="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-700 fw-500 border-light-md theme-dark-bg"
          cols="30" rows="10" v-model="commentContent" placeholder="What's on your mind?"></textarea>
      </div>

      <div class="card-body p-0">

        <el-button type="warning" :loading="commenting" plain @click="createComment" round>
          <i class=" font-xs feather-message-circle me-2 "></i>Comment
        </el-button>
      </div>
    </div>
  </div>

  <!-- comment list -->
  <div v-if="isDetail && items.length" class="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3">
    <div class="card-body d-flex align-items-center p-0">
      <h4 class="fw-700 mb-0 font-xssss text-grey-900">Comments</h4>
    </div>
    <div class="card-body pt-4 pb-3 pe-4 d-block ps-5" v-for="(item, index) in items" :key="index">
      <router-link class="avatar position-absolute left-0 ms-4 mt-1"
        :to="'/user/profile?uid=' + item?.UserInfo?.UserAddress">
        <Avatar :size="35" :avatar="item?.UserInfo?.Avatar" />
      </router-link>

      <div class="chat p-3 bg-greylight rounded-xxl d-block text-left theme-dark-bg">
        <h4 class="fw-700 text-grey-900 font-xssss mt-0 mb-1">
          <router-link class="text-grey-900" :to="'/user/profile?uid=' + item?.UserInfo?.UserAddress">
            {{ item?.UserInfo?.Nickname }}
          </router-link>
          <span class="font-xssss fw-500 ms-2 mt-1 lh-3 text-grey-500">{{ timestampToTime(item?.CreatedAt)
          }}</span>
        </h4>
        <p class="fw-500 text-grey-700 lh-20 font-xssss w-100 mt-2 mb-0">
          <span v-html="formatContent(item?.Content)"></span>
        </p>
      </div>
    </div>

    <div v-if="loading" class="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3">
      <div class="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
        <div class="stage">
          <div class="dot-typing"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- comment list -->

</template>

<script>
import { mixins } from "@/mixins/index";
import { tweetCreate, tweetRelease, tweetLike, commentRelease, commentList } from '@/utils/api'
import Avatar from '@/components/Avatar.vue'
import Image from '@/components/Image.vue'

export default {
  mixins: [mixins],
  components: {
    Avatar,
    Image,
  },
  data() {
    return {
      forward: false,
      forwarding: false,
      forwardContent: "",
      forwardCount: this.tweet.ForwardCount || 0,

      comment: false,
      commenting: false,
      commentContent: "",
      commentCount: this.tweet.CommentCount || 0,

      liking: false,
      likeCount: this.tweet.LikeCount || 0,
      isTwLike: this.tweet.isTwLike || false,

    }
  },
  props: {
    tweet: Object,
    isDetail: Boolean,
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.loadMore();
  },
  methods: {
    handleScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight - 10) {
        this.loadMore()
      }
    },
    loadMore() {
      if (!this.more || this.loading || !this.isDetail) {
        return
      }

      if (this.page <= 1) {
        this.items = []
      }

      this.loading = true
      commentList(this.tweet.TwAddress, this.page).then(items => {
        items.forEach(item => {
          this.items.push(item)
        });

        if (items.length < 1) {
          this.more = false
        }

        this.page++
      }).finally(() => {
        this.loading = false
      })
    },
    filterAttachment(str, type) {
      var arr = [];
      str.split(',').forEach(link => {
        arr.push(this.urlFileType(link))
      });

      return arr.filter(function (item) {
        return item.type == type
      });
    },
    images(str) {
      var arr = []
      this.filterAttachment(str, 'image').forEach(item => {
        arr.push(item.url)
      });
      return arr
    },
    async createForward() {
      if (this.forwarding) {
        return
      }

      try {
        this.forwarding = true
        const data = await tweetCreate({ content: this.forwardContent, attachment: "", forwardId: this.tweet.TwAddress })
        const signMsg = await this.signData(data.SignMsg)

        await tweetRelease({
          id: data.Id,
          sign: signMsg,
        })

        this.forwardContent = ""
        this.forward = false
        this.forwardCount++

        this.showSuccess()
      } catch (error) {
        this.showError(error)
        console.log(error);
      } finally {
        this.forwarding = false
      }
    },
    createComment() {
      if (this.commenting) {
        return
      }

      if (this.commentContent == "") {
        this.showError("content is emtpy")
        return
      }

      this.commenting = true
      commentRelease(this.tweet.TwAddress, this.commentContent).then(() => {
        this.commentContent = ""
        this.commentCount++
        this.comment = false
      }).catch(this.showError).finally(() => {
        this.commenting = false
      })

    },
    handleTweetLike() {
      if (this.liking) {
        return
      }

      this.liking = true
      tweetLike(this.tweet.TwAddress).then(() => {
        if (!this.isTwLike) {
          this.likeCount++
        } else {
          this.likeCount--
        }

        this.isTwLike = !this.isTwLike
      }).catch(this.showError).finally(() => {
        this.liking = false
      })
    },
    goDetail(id) {
      if (this.isDetail) {
        this.$router.replace('/tweet/detail?id=' + id)
      } else {
        this.$router.push('/tweet/detail?id=' + id)
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.text-content {
  color: #303030;
  font-size: 14px !important;
}


video {
  width: 100%;
  height: auto;
}

@media (min-width: 990px) {
  video {
    width: 30%;
    height: auto;
  }
}

</style>
