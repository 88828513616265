import { createStore } from 'vuex'
import Web3 from 'web3';
import helper from '@/utils/helper';

export default createStore({
  state: {
    web3Provider: null,
    uid: "",
    token: "",
    user: null,
    guest: null,
  },
  getters: {
  },
  mutations: {
    setUid(state, payload) {
      state.uid = payload
    },
    setToken(state, payload) {
      state.token = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
    setGuest(state, payload) {
      state.guest = payload
    },
    setWeb3Provider(state, payload) {
      state.web3Provider = payload
    }
  },
  actions: {
    initProvider({ commit }) {
      const web3 = new Web3()
      if (helper.hasEthereum()) {
        web3.setProvider(window.ethereum)
      }

      commit('setWeb3Provider', web3)
    },
  },
  modules: {
  }
})
