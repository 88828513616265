import axios from 'axios'
import store from '../store'

//使用create方法创建axios实例
export const Http = axios.create({
    // timeout: 7000, // 请求超时时间
    baseURL: process.env.VUE_APP_API,
})
// 添加请求拦截器
Http.interceptors.request.use(config =>  {
    config.headers['X-Token'] = store.state.token

    if (config.method == 'post') {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }

    return config
})
// 添加响应拦截器
Http.interceptors.response.use(response => {
    if (response.data.Code != 0) {
        // user not login
        if (response.data.Code == -1 && response.config.headers['X-Token']) {
            localStorage.removeItem(store.state.uid.toLowerCase())
            store.state.token = ""
        }

        return Promise.reject(response.data.Msg)
    }

    

    return response.data.Data
}, error => {
    console.log('error', error)
    // const msg = error.Message !== undefined ? error.Message : ''

    return Promise.reject(error)
})