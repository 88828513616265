<template>
  <div class="">
    <div class="middle-sidebar-bottom">
      <div class="middle-sidebar-left">
        <!-- loader wrapper -->
        <div class="preloader-wrap p-3">
          <div class="box shimmer">
            <div class="lines">
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
            </div>
          </div>
          <div class="box shimmer mb-3">
            <div class="lines">
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
            </div>
          </div>
          <div class="box shimmer">
            <div class="lines">
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
            </div>
          </div>
        </div>
        <!-- loader wrapper -->
        <div class="row feed-body">
          <div class="col-xl-12 col-xxl-12 col-lg-12">
            <CreatePost :callback="reload"/>

            <TweetCell v-for="(tweet, index) in items" :key="index" :tweet="tweet" />
            <div v-if="loading" class="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3">
              <div class="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                <div class="stage">
                  <div class="dot-typing"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixins/index";
import { getFollowTweets } from '@/utils/api'
import TweetCell from '@/components/TweetCell.vue'
import CreatePost from '@/components/CreatePost.vue'

export default {
  mixins: [mixins],
  components: {
    TweetCell,
    CreatePost,
  },
  data() {
    return {

    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.loadMore();
  },
  methods: {
    handleScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight - 10) {
        this.loadMore()
      }
    },
    reload(){
      this.cursor = ""
      this.loadMore()
      console.log('reload');
    },
    loadMore() {
      if (!this.more || this.loading) {
        return
      }

      if (this.cursor == "") {
        this.items = []
      }

      this.loading = true
      getFollowTweets(this.cursor).then(items => {
        items.forEach(item => {
          this.items.push(item)
          this.cursor = item.Cursor
        });

        if (items.length < 1) {
          this.more = false
        }
      }).catch(this.showError).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
