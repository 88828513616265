<template>
  <div class="main-wrapper">
    <!-- navigation top-->
    <div class="nav-header bg-white shadow-xs border-0">
      <div class="nav-top">
        <a href="/">
          <img height="40" src="./assets/images/logo.svg">
        </a>

        <a href="javascript:void(0);" @click="showSearch = true" class="ms-auto mob-menu"><i
            class="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i></a>

      </div>

      <form @keyup.enter="handleSearch" @submit.prevent class="float-left header-search">
        <div class="form-group mb-0 icon-input">
          <i class="feather-search font-sm text-grey-400"></i>
          <input v-model="keyword" type="text" placeholder="Start typing to search.."
            class="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg">
        </div>
      </form>

      <router-link to="/" custom v-slot="{ isActive, navigate }">
        <div @click="navigate" class="p-2 cursor-pointer text-center ms-3 menu-icon center-menu-icon">
          <i :class="isActive ? 'alert-primary  text-current' : 'bg-greylight  text-grey-500'"
            class="feather-home font-lg btn-round-lg theme-dark-bg"></i>
        </div>
      </router-link>

      <router-link to="/foryou" custom v-slot="{ isActive, navigate }">
        <div @click="navigate" class="p-2 cursor-pointer text-center menu-icon center-menu-icon">
          <i :class="isActive ? 'alert-primary  text-current' : 'bg-greylight  text-grey-500'"
            class="feather-users font-lg btn-round-lg theme-dark-bg"></i>
        </div>
      </router-link>

      <router-link to="/secret" custom v-slot="{ isActive, navigate }">
        <div @click="navigate" class="p-2 cursor-pointer text-center menu-icon center-menu-icon">
          <i :class="isActive ? 'alert-primary  text-current' : 'bg-greylight  text-grey-500'"
            class="feather-lock font-lg btn-round-lg theme-dark-bg"></i>
        </div>
      </router-link>

      <div class="p-2 text-center ms-auto position-relative dropdown-menu-icon menu-icon cursor-pointer">
        <i class="feather-settings animation-spin d-inline-block font-xl text-current"></i>
        <div class="dropdown-menu-settings switchcolor-wrap">
          <h4 class="fw-700 font-sm mb-4">Settings</h4>
          <h6 class="font-xssss text-grey-500 fw-700 mb-3 d-block">Choose Color Theme</h6>
          <ul>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="red" checked=""><i class="ti-check"></i>
                <span class="circle-color bg-red" style="background-color: #ff3b30;"></span>
              </label>
            </li>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="green"><i class="ti-check"></i>
                <span class="circle-color bg-green" style="background-color: #4cd964;"></span>
              </label>
            </li>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="blue" checked=""><i class="ti-check"></i>
                <span class="circle-color bg-blue" style="background-color: #132977;"></span>
              </label>
            </li>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="pink"><i class="ti-check"></i>
                <span class="circle-color bg-pink" style="background-color: #ff2d55;"></span>
              </label>
            </li>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="yellow"><i class="ti-check"></i>
                <span class="circle-color bg-yellow" style="background-color: #ffcc00;"></span>
              </label>
            </li>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="orange"><i class="ti-check"></i>
                <span class="circle-color bg-orange" style="background-color: #ff9500;"></span>
              </label>
            </li>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="gray"><i class="ti-check"></i>
                <span class="circle-color bg-gray" style="background-color: #8e8e93;"></span>
              </label>
            </li>

            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="brown"><i class="ti-check"></i>
                <span class="circle-color bg-brown" style="background-color: #D2691E;"></span>
              </label>
            </li>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="darkgreen"><i class="ti-check"></i>
                <span class="circle-color bg-darkgreen" style="background-color: #228B22;"></span>
              </label>
            </li>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="deeppink"><i class="ti-check"></i>
                <span class="circle-color bg-deeppink" style="background-color: #FFC0CB;"></span>
              </label>
            </li>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="cadetblue"><i class="ti-check"></i>
                <span class="circle-color bg-cadetblue" style="background-color: #5f9ea0;"></span>
              </label>
            </li>
            <li>
              <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="darkorchid"><i class="ti-check"></i>
                <span class="circle-color bg-darkorchid" style="background-color: #9932cc;"></span>
              </label>
            </li>
          </ul>

          <div class="card bg-transparent-card border-0 d-block mt-3">
            <h4 class="d-inline font-xssss mont-font fw-700">Header Background</h4>
            <div class="d-inline float-right mt-1">
              <label class="toggle toggle-menu-color"><input type="checkbox"><span class="toggle-icon"></span></label>
            </div>
          </div>
          <div class="card bg-transparent-card border-0 d-block mt-3">
            <h4 class="d-inline font-xssss mont-font fw-700">Menu Position</h4>
            <div class="d-inline float-right mt-1">
              <label class="toggle toggle-menu"><input type="checkbox"><span class="toggle-icon"></span></label>
            </div>
          </div>
          <div class="card bg-transparent-card border-0 d-block mt-3">
            <h4 class="d-inline font-xssss mont-font fw-700">Dark Mode</h4>
            <div class="d-inline float-right mt-1">
              <label class="toggle toggle-dark"><input type="checkbox"><span class="toggle-icon"></span></label>
            </div>
          </div>

        </div>
      </div>

      <router-link to="/settings">
        <a v-if="user" href="javascript:void(0);" class="p-0 ms-3 menu-icon">
          <Avatar :size="38" :avatar="user?.Avatar" />
        </a>
      </router-link>

      <a v-if="!user" @click="connect" href="javascript:void(0);" title="Login"
        class="p-2 text-center ms-3 menu-icon chat-active-btn"><i class="feather-user font-xl text-current"></i></a>
      <!-- <a v-if="!user" @click="connect" href="javascript:void(0);" class="p-1 menu-icon lh-28 w100 bg-primary-gradiant text-white text-center font-xsss fw-600 ls-1 rounded-xl">Login</a> -->
    </div>
    <!-- navigation top -->

    <!-- navigation left -->
    <!-- <nav class="navigation scroll-bar">
      <div class="container ps-0 pe-0">
        <div class="nav-content">
          <div class="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
            <div class="nav-caption fw-600 font-xssss text-grey-500"><span>New </span>Feeds</div>
            <ul class="mb-1 top-content">
              <li class="logo d-none d-xl-block d-lg-block"></li>
              <li>
                <router-link to="/" class="nav-content-bttn open-font"><i
                    class="feather-zap btn-round-md bg-mini-gradiant me-3"></i><span>Popular</span>
                </router-link>

              </li>
              <li>
                <router-link to="/foryou" class="nav-content-bttn open-font"><i
                    class="feather-user btn-round-md bg-primary-gradiant me-3"></i><span>For you</span></router-link>
              </li>
            </ul>
          </div>

          <div v-if="user" class="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
            <div class="nav-caption fw-600 font-xssss text-grey-500"><span></span> Account</div>
            <ul class="mb-1">
              <li class="logo d-none d-xl-block d-lg-block"></li>

              <li>
                <router-link :to="'/user/profile?uid=' + uid" class="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                    class="font-sm feather-user me-3 text-grey-500"></i><span>Profile</span></router-link>
              </li>
              <li>
                <router-link to="/settings" class="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                    class="font-sm feather-settings me-3 text-grey-500"></i><span>Settings</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav> -->
    <!-- navigation left -->

    <!-- header wrapper -->
    <!-- <div class="navigation scroll-bar">
      <router-link to="/">Home</router-link> |
      <router-link to="/settings">About</router-link>
    </div> -->
    <!-- header wrapper -->

    <!-- main content -->
    <div class="main-content right-chat-active">
      <router-view v-if="load" :key="$route.fullPath" />
    </div>
    <!-- main content -->

    <div class="app-footer border-0 shadow-lg bg-primary-gradiant">
      <router-link to="/" class="nav-content-bttn nav-center"><i class="feather-home"></i></router-link>
      <router-link to="/foryou" class="nav-content-bttn"><i class="feather-users"></i></router-link>
      <router-link to="/secret" class="nav-content-bttn"><i class="feather-lock"></i></router-link>

      <router-link to="/settings" v-if="user" class="lh-1 nav-content-bttn">
        <Avatar :size="30" :avatar="user?.Avatar" />
      </router-link>
      <a v-if="!user" @click="connect" href="javascript:void(0);" class="nav-content-bttn"><i
          class="feather-user"></i></a>
    </div>

    <el-backtop :right="50" :bottom="50" :visibility-height="400" />

    <div class="app-header-search" :class="{ show: showSearch }">
      <form class="search-form" @keyup.enter="handleSearch" @submit.prevent>
        <div class="form-group searchbox mb-0 border-0 p-1">
          <input type="text" v-model="keyword" class="form-control border-0" placeholder="Search...">
          <i class="input-icon">

          </i>
          <a href="javascript:void(0);" @click="showSearch = false"
            class="ms-1 mt-1 d-inline-block close searchbox-close">
            <i class="ti-close font-xs"></i>
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixins/index";
import Avatar from '@/components/Avatar.vue'

export default {
  mixins: [mixins],
  components: { Avatar },
  data() {
    return {
      load: false,
    }
  },
  created() {
    this.initProvider()

    // 已经安装钱包
    if (this.provider.currentProvider) {
      const { ethereum } = window;
      ethereum.on('accountsChanged', this.handleAccountsChanged);

      this.provider.eth.getAccounts().then(this.handleAccountsChanged).catch(this.showError).finally(() => {
        this.load = true
        console.log("App done");
      })
    } else {
      if (localStorage.getItem("guest")) {
        this.handleGuest()
      }
      this.load = true
      console.log("App done");
    }
  },
}
</script>

<style lang="scss">
.nav-header,
.app-footer,
.app-header-search {
  z-index: 2001;
}

.main-content {
  padding-left: 0;
}

.app-footer {
  background: linear-gradient(to right, #673BB7, #573BB7) !important;
}

.theme-dark {
  .text-grey-900 {
    color: rgba($color: #fff, $alpha: 0.9) !important;
  }

  .text-grey-700 {
    color: rgba($color: #fff, $alpha: 0.7) !important;
  }
}

.el-avatar>img {
  width: 100%;
}

.tweet-cell {
  .el-image {
    width: 33.33%;
    height: 0;
    min-height: 100% !important;
    padding-bottom: 100%;
    position: relative;

    .el-image__inner {
      position: absolute;
      width: 100%;
      height: 100%;
      min-height: 100%;
    }
  }
}
</style>
