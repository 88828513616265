<template>
  <div class="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
    <div class="card-body p-0 mt-3 position-relative">
      <figure class="avatar position-absolute ms-2 mt-1 top-5">
        <Avatar :size="28" :avatar="user?.Avatar" />
      </figure>
      <textarea name="message"
        class="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-700 fw-500 border-light-md theme-dark-bg"
        cols="30" rows="10" v-model="content" placeholder="What's on your mind?"></textarea>
    </div>
    <div class="card-body d-flex p-0 mt-0">
      <a @click="chooseUpload('image')"
        class="cursor-pointer d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark me-2"><i
          class="font-md text-success feather-image"></i></a>

      <a @click="chooseUpload('video')"
        class="cursor-pointer d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark me-2"><i
          class="font-md text-success feather-video"></i></a>

      <a @click="chooseUpload('file')"
        class="cursor-pointer d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark me-2"><i
          class="font-xs text-success feather-paperclip"></i></a>

      <div class="ms-auto" aria-expanded="false">
        <el-button type="primary" :loading="posting" @click="createPost" round>Post</el-button>
      </div>
    </div>
    <el-upload class="card-img mt-2" v-if="uploadType == 'image'" ref="imageRef" multiple :limit="9" accept="image/*"
      v-model:file-list="images" :action="ipfsApi + 'add'" list-type="picture-card">
      <el-icon>
        <Plus />
      </el-icon>
    </el-upload>

    <el-upload class="mt-2" v-if="uploadType == 'video'" ref="videoRef" accept="video/*" v-model:file-list="videos"
      :action="ipfsApi + 'add'">
      Click here to upload video
    </el-upload>

    <el-upload class="mt-2" v-if="uploadType == 'file'" ref="fileRef" multiple :limit="9" v-model:file-list="files"
      :action="ipfsApi + 'add'">
      Click here to upload files
    </el-upload>
  </div>
</template>

<script>
import { mixins } from "@/mixins/index";
import { tweetCreate, tweetRelease } from '@/utils/api'
import Avatar from '@/components/Avatar.vue'

export default {
  mixins: [mixins],
  components: { Avatar },
  props: {
    callback: Function,
  },
  data() {
    return {
      posting: false,
      content: "",

      uploadType: "", // image,video,file
      images: [],
      videos: [],
      files: [],
    };
  },
  methods: {
    chooseUpload(str) {
      this.images = []
      this.videos = []
      this.files = []

      this.$refs.imageRef?.abort()
      this.$refs.videoRef?.abort()
      this.$refs.fileRef?.abort()

      if (this.uploadType == str) {
        this.uploadType = ""
      } else {
        this.uploadType = str
      }
    },
    handleFinish(info) {
      if (info.file.status == "finished" && info.event) {
        info.file.url = [
          process.env.VUE_APP_RESOURCE,
          info.event.target.response.Hash,
          '?filename=',
          info.file.name
        ].join("")
      }
    },
    formatAttachment() {
      const attachment = []
      this.images.forEach(file => {
        if (file?.response?.Hash) {
          attachment.push([
            process.env.VUE_APP_RESOURCE,
            file.response.Hash,
            '?filename=',
            file.name
          ].join(""))
        }
      });

      this.videos.forEach(file => {
        if (file?.response?.Hash) {
          attachment.push([
            process.env.VUE_APP_RESOURCE,
            file.response.Hash,
            '?filename=',
            file.name
          ].join(""))
        }
      });

      this.files.forEach(file => {
        if (file?.response?.Hash) {
          attachment.push([
            process.env.VUE_APP_RESOURCE,
            file.response.Hash,
            '?filename=',
            file.name
          ].join(""))
        }
      });

      return attachment.join(",")
    },
    async createPost() {
      if (this.posting) {
        return
      }

      try {
        this.posting = true
        const data = await tweetCreate({ content: this.content, attachment: this.formatAttachment(), forwardId: "" })
        const signMsg = await this.signData(data.SignMsg)

        await tweetRelease({
          id: data.Id,
          sign: signMsg,
        })

        this.content = ""
        this.uploadType = ""
        this.images = []
        this.videos = []
        this.files = []

        this.showSuccess()

        if (this.callback) {
          this.callback()
        }
      } catch (error) {
        this.showError(error)
        console.log(error);
      } finally {
        this.posting = false
      }
    }
  },
}
</script>

<style  lang="scss">
.card-img {

  .el-upload-list--picture-card {
    .el-upload-list__item {

      width: 100px;
      height: 100px;
    }


    .el-upload--picture-card {
      width: 100px;
      height: 100px;
    }
  }


  .el-upload-list--picture-card .el-progress {
    width: 80px !important;
    height: 80px !important;
  }

  .el-progress-circle {
    width: 80px !important;
    height: 80px !important;
  }

}
</style>
  