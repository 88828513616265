<template>
    <el-image :hide-on-click-modal="true" :src="formatImageUrl(src)" fit="cover" :preview-src-list="WeiboImagesUrl(list)" :initial-index="index">
        {{formatImageUrl(src)}}
        <template #placeholder>
            <div class="image-slot" v-loading="true" element-loading-text="Loading..."></div>
        </template>
        <template #error>
            <div class="image-slot">
                <el-empty :image-size="100" image="images/image_error.svg" description="Load Error" />
            </div>
        </template>
    </el-image>
</template>

<script>
import { mixins } from "@/mixins/index";
export default {
    mixins: [mixins],
    props: {
        src: String,
        list: Array,
        index: Number,
    },
}
</script>
  
<style scoped lang="scss">
.el-image {
    min-height: 200px;
    width: 100%;
}

.image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    color: var(--el-text-color-secondary);
    font-size: 14px;

    img {
        height: 50%;
    }
}
</style>
  