<template>
    <el-avatar :size="size" :src="formatImageUrl(avatar)" @error="errorHandler">
        <img src="images/avatar_default.png" />
    </el-avatar>
</template>

<script>
import { mixins } from "@/mixins/index";
export default {
    mixins: [mixins],
    props: {
        size: Number,
        avatar: String,
    },
    methods: {
        errorHandler: () => true,
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
  