<template>
  <div class="home">
    <div class="middle-sidebar-bottom">
      <div class="middle-sidebar-left">
        <!-- loader wrapper -->
        <div class="preloader-wrap p-3">
          <div class="box shimmer">
            <div class="lines">
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
            </div>
          </div>
          <div class="box shimmer mb-3">
            <div class="lines">
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
            </div>
          </div>
          <div class="box shimmer">
            <div class="lines">
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
              <div class="line s_shimmer"></div>
            </div>
          </div>
        </div>
        <!-- loader wrapper -->
        <div class="row feed-body">
          <div class="col-xl-12 col-xxl-12 col-lg-12">
            
            <CreatePost :callback="function(){$router.push('/foryou')}"/>

            <TweetCell v-for="(tweet, index) in items" :key="index" :tweet="tweet" />
            
            <div v-if="loading" class="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3">
              <div class="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                <div class="stage">
                  <div class="dot-typing"></div>
                </div>
              </div>
            </div>


          </div>
<!--
          <div class="col-xl-4 col-xxl-3 col-lg-4 ps-md-0">
            <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
              <div class="card-body d-flex align-items-center p-4">
                <h4 class="fw-700 mb-0 font-xssss text-grey-900">Friend Request</h4>
                <a href="#" class="fw-600 ms-auto font-xssss text-primary">See all</a>
              </div>
              <div class="card-body d-flex pt-4 ps-4 pe-4 pb-0 border-top-xs bor-0">
                <figure class="avatar me-3"><img src="images/avatar_default.png" alt="image"
                    class="shadow-sm rounded-circle w45"></figure>
                <h4 class="fw-700 text-grey-900 font-xssss mt-1">Anthony Daugloi <span
                    class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">12 mutual friends</span></h4>
              </div>
              <div class="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                <a href="#"
                  class="p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Confirm</a>
                <a href="#"
                  class="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">Delete</a>
              </div>

              <div class="card-body d-flex pt-0 ps-4 pe-4 pb-0">
                <figure class="avatar me-3"><img src="images/avatar_default.png" alt="image"
                    class="shadow-sm rounded-circle w45"></figure>
                <h4 class="fw-700 text-grey-900 font-xssss mt-1">Mohannad Zitoun <span
                    class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">12 mutual friends</span></h4>
              </div>
              <div class="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                <a href="#"
                  class="p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Confirm</a>
                <a href="#"
                  class="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">Delete</a>
              </div>

              <div class="card-body d-flex pt-0 ps-4 pe-4 pb-0">
                <figure class="avatar me-3"><img src="images/avatar_default.png" alt="image"
                    class="shadow-sm rounded-circle w45"></figure>
                <h4 class="fw-700 text-grey-900 font-xssss mt-1">Mohannad Zitoun <span
                    class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">12 mutual friends</span></h4>
              </div>
              <div class="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                <a href="#"
                  class="p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Confirm</a>
                <a href="#"
                  class="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">Delete</a>
              </div>
            </div>

            <div class="card w-100 shadow-xss rounded-xxl border-0 p-0 ">
              <div class="card-body d-flex align-items-center p-4 mb-0">
                <h4 class="fw-700 mb-0 font-xssss text-grey-900">Friend Request</h4>
                <a href="#" class="fw-600 ms-auto font-xssss text-primary">See all</a>
              </div>
              <div class="card-body bg-transparent-card d-flex p-3 bg-greylight ms-3 me-3 rounded-3">
                <figure class="avatar me-2 mb-0"><img src="images/avatar_default.png" alt="image"
                    class="shadow-sm rounded-circle w45"></figure>
                <h4 class="fw-700 text-grey-900 font-xssss mt-2">Anthony Daugloi <span
                    class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">12 mutual friends</span></h4>
                <a href="#" class="btn-round-sm bg-white text-grey-900 feather-chevron-right font-xss ms-auto mt-2"></a>
              </div>
              <div class="card-body bg-transparent-card d-flex p-3 bg-greylight m-3 rounded-3"
                style="margin-bottom: 0 !important;">
                <figure class="avatar me-2 mb-0"><img src="images/avatar_default.png" alt="image"
                    class="shadow-sm rounded-circle w45"></figure>
                <h4 class="fw-700 text-grey-900 font-xssss mt-2"> David Agfree <span
                    class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">12 mutual friends</span></h4>
                <a href="#" class="btn-round-sm bg-white text-grey-900 feather-plus font-xss ms-auto mt-2"></a>
              </div>
              <div class="card-body bg-transparent-card d-flex p-3 bg-greylight m-3 rounded-3">
                <figure class="avatar me-2 mb-0"><img src="images/avatar_default.png" alt="image"
                    class="shadow-sm rounded-circle w45"></figure>
                <h4 class="fw-700 text-grey-900 font-xssss mt-2">Hugury Daugloi <span
                    class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">12 mutual friends</span></h4>
                <a href="#" class="btn-round-sm bg-white text-grey-900 feather-plus font-xss ms-auto mt-2"></a>
              </div>

            </div>

            <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3">
              <div class="card-body d-flex align-items-center p-4">
                <h4 class="fw-700 mb-0 font-xssss text-grey-900">Suggest Group</h4>
                <a href="#" class="fw-600 ms-auto font-xssss text-primary">See all</a>
              </div>
              <div class="card-body d-flex pt-4 ps-4 pe-4 pb-0 overflow-hidden border-top-xs bor-0">
                <img src="https://via.placeholder.com/400x300.png" alt="img" class="img-fluid rounded-xxl mb-2">
              </div>
              <div class="card-body dd-block pt-0 ps-4 pe-4 pb-4">
                <ul class="memberlist mt-1 mb-2 ms-0 d-block">
                  <li class="w20"><a href="#"><img src="images/avatar_default.png" alt="user"
                        class="w35 d-inline-block" style="opacity: 1;"></a></li>
                  <li class="w20"><a href="#"><img src="images/avatar_default.png" alt="user"
                        class="w35 d-inline-block" style="opacity: 1;"></a></li>
                  <li class="w20"><a href="#"><img src="images/avatar_default.png" alt="user"
                        class="w35 d-inline-block" style="opacity: 1;"></a></li>
                  <li class="w20"><a href="#"><img src="images/avatar_default.png" alt="user"
                        class="w35 d-inline-block" style="opacity: 1;"></a></li>
                  <li class="last-member"><a href="#"
                      class="bg-greylight fw-600 text-grey-500 font-xssss w35 ls-3 text-center"
                      style="height: 35px; line-height: 35px;">+2</a></li>
                  <li class="ps-3 w-auto ms-1"><a href="#" class="fw-600 text-grey-500 font-xssss">Member apply</a>
                  </li>
                </ul>
              </div>



            </div>

            <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
              <div class="card-body d-flex align-items-center p-4">
                <h4 class="fw-700 mb-0 font-xssss text-grey-900">Suggest Pages</h4>
                <a href="#" class="fw-600 ms-auto font-xssss text-primary">See all</a>
              </div>
              <div class="card-body d-flex pt-4 ps-4 pe-4 pb-0 overflow-hidden border-top-xs bor-0">
                <img src="https://via.placeholder.com/400x300.png" alt="img" class="img-fluid rounded-xxl mb-2">
              </div>
              <div class="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                <a href="#" class="p-2 lh-28 w-100 bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl"><i
                    class="feather-external-link font-xss me-2"></i> Like Page</a>
              </div>

              <div class="card-body d-flex pt-0 ps-4 pe-4 pb-0 overflow-hidden">
                <img src="https://via.placeholder.com/400x300.png" alt="img"
                  class="img-fluid rounded-xxl mb-2 bg-lightblue">
              </div>
              <div class="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                <a href="#" class="p-2 lh-28 w-100 bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl"><i
                    class="feather-external-link font-xss me-2"></i> Like Page</a>
              </div>


            </div>


            <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
              <div class="card-body d-flex align-items-center  p-4">
                <h4 class="fw-700 mb-0 font-xssss text-grey-900">Event</h4>
                <a href="#" class="fw-600 ms-auto font-xssss text-primary">See all</a>
              </div>
              <div class="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                <div class="bg-success me-2 p-3 rounded-xxl">
                  <h4 class="fw-700 font-lg ls-3 lh-1 text-white mb-0"><span
                      class="ls-1 d-block font-xsss text-white fw-600">FEB</span>22</h4>
                </div>
                <h4 class="fw-700 text-grey-900 font-xssss mt-2">Meeting with clients <span
                    class="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">41 madison ave, floor 24 new work, NY
                    10010</span> </h4>
              </div>

              <div class="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                <div class="bg-warning me-2 p-3 rounded-xxl">
                  <h4 class="fw-700 font-lg ls-3 lh-1 text-white mb-0"><span
                      class="ls-1 d-block font-xsss text-white fw-600">APR</span>30</h4>
                </div>
                <h4 class="fw-700 text-grey-900 font-xssss mt-2">Developer Programe <span
                    class="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">41 madison ave, floor 24 new work, NY
                    10010</span> </h4>
              </div>

              <div class="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                <div class="bg-primary me-2 p-3 rounded-xxl">
                  <h4 class="fw-700 font-lg ls-3 lh-1 text-white mb-0"><span
                      class="ls-1 d-block font-xsss text-white fw-600">APR</span>23</h4>
                </div>
                <h4 class="fw-700 text-grey-900 font-xssss mt-2">Aniversary Event <span
                    class="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">41 madison ave, floor 24 new work, NY
                    10010</span> </h4>
              </div>

            </div>
          </div>
-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixins/index";
import { getHome } from '@/utils/api'
import TweetCell from '@/components/TweetCell.vue'
import CreatePost from '@/components/CreatePost.vue'

export default {
  mixins: [mixins],
  components: {
    TweetCell,
    CreatePost,
  },
  data() {
    return {

    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.loadMore();
  },
  methods: {
    handleScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight - 10) {
        this.loadMore()
      }
    },
    loadMore() {
      if (!this.more || this.loading) {
        return
      }

      if (this.cursor == "") {
        this.items = []
      }

      this.loading = true
      getHome(this.cursor).then(items => {
        items.forEach(item => {
          this.items.push(item)
          this.cursor = item.Cursor
        });

        if (items.length < 1) {
          this.more = false
        }
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
