import { mapActions } from 'vuex'
import helper from '@/utils/helper'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { authorize, nonce, userInfo } from '@/utils/api'
import { ElNotification } from 'element-plus'
import mime from 'mime'

dayjs.extend(relativeTime)

export const mixins = {
    data() {
        return {
            loging: false,
            loading: false,
            more: true,
            page: 1,
            cursor: "",
            items: [],
            ipfsApi: process.env.VUE_APP_IPFS_API,
            ipfsResource: process.env.VUE_APP_RESOURCE,
            keyword: "",
            showSearch: false,
        };
    },
    computed: {
        uid: {
            get() { return this.$store.state.uid },
            set(value) { this.$store.commit('setUid', value) }
        },
        token: {
            get() { return this.$store.state.token },
            set(value) { this.$store.commit('setToken', value) }
        },
        user: {
            get() { return this.$store.state.user },
            set(value) { this.$store.commit('setUser', value) }
        },
        guest: {
            get() { return this.$store.state.guest },
            set(value) { this.$store.commit('setGuest', value) }
        },
        provider: {
            get() { return this.$store.state.web3Provider },
            set(value) { this.$store.commit('setWeb3Provider', value) }
        }
    },
    methods: {
        ...mapActions([
            'initProvider',
        ]),
        connect() {
            if (this.loging) {
                return
            }

            const msg = ElNotification.info({
                message: 'Login in progress, please wait',
                showClose: false,
                duration: 0,
            })

            this.loging = true

            if (helper.hasEthereum()) {
                window.ethereum.enable().then(this.login).then(() => {
                    this.showSuccess("Login success")
                }).catch(this.showError).finally(() => {
                    msg.close()
                    this.loging = false
                })
            } else {
                this.handleGuest()
                this.login().then(() => {
                    this.showSuccess("Login success")
                }).catch(this.showError).finally(() => {
                    msg.close()
                    this.loging = false
                })
            }
        },
        async login() {
            const sign1 = await this.signData(this.uid)
            const res = await nonce({ "user_address": this.uid, "sign": sign1 })
            const sign2 = await this.signData(res.SignMsg)
            const user = await authorize({ "nonce": res.Nonce, "sign": sign2, "user_address": this.uid })
            localStorage.setItem(this.uid.toLowerCase(), user.Token)
            this.token = user.Token
            this.user = user
        },
        handleGuest() {
            const web3 = this.provider;
            const guest = localStorage.getItem("guest")

            if (!guest) {
                const account = web3.eth.accounts.create()
                localStorage.setItem("guest", JSON.stringify(account))
            }

            this.guest = JSON.parse(localStorage.getItem("guest"))
            this.handleAccountsChanged([this.guest.address])
        },
        async signData(msg) {
            let signMsg

            const web3 = this.provider
            if (web3.currentProvider) {
                signMsg = await web3.eth.personal.sign(
                    web3.utils.utf8ToHex(msg),
                    this.uid,
                    '',
                );
            } else {
                const sign = await web3.eth.accounts.sign(
                    web3.utils.utf8ToHex(msg),
                    this.guest.privateKey,
                );
                signMsg = sign.signature
            }

            return signMsg
        },
        logout() {
            localStorage.removeItem(this.uid.toLowerCase())
            this.user = null
            this.uid = null
            this.token = null
            this.$router.replace({ path: '/' })
        },
        refreshUserInfo() {
            return userInfo(this.uid).then((res) => {
                this.user = res.user
            })
        },
        getUserInfo(uid) {
            return userInfo(uid)
        },
        handleAccountsChanged(accounts) {
            var that = this
            const uid = accounts[0] || ""
            if (uid) {
                // 格式化地址, matemask 有时候返回地址是全小写
                that.uid = that.provider.utils.toChecksumAddress(uid)
            }

            console.log("handleAccountsChanged", uid);
            if (that.uid) {
                const token = localStorage.getItem(that.uid.toLowerCase())
                if (token) {
                    that.token = token
                    that.refreshUserInfo()
                } else {
                    this.user = null
                    this.token = null
                }
            } else {
                this.user = null
                this.token = null
            }
        },
        timestampToTime(ts) {
            const t = dayjs.unix(ts)
            // 1天内人性化显示
            if (ts > this.now().unix() - 86400) {
                return t.fromNow()
            }

            return t.format('YYYY-MM-DD HH:mm')
        },
        now() {
            return dayjs();
        },
        showError(msg) {
            ElNotification({
                message: msg?.message || msg,
                type: 'error',
            })
        },
        showSuccess(msg) {
            ElNotification({
                message: msg || "Success",
                type: 'success',
            })
        },
        urlFileType(str) {
            const link = new URL(this.formatImageUrl(str))

            const filename = link.searchParams.get("filename")

            // 没参数统一为 image
            if (filename == "") {
                return {
                    type: 'image',
                    url: link.href,
                }
            }

            const typeRegs = {
                "image": /\.(jpeg|jpg|gif|png|bmp|webp|svg)$/,
                "video": /\.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4)$/,
                "audio": /\.(mp3|ogg|wav)$/,
            }

            for (const [type, reg] of Object.entries(typeRegs)) {
                const res = link.href.toLowerCase().match(reg)

                if (res != null) {
                    return {
                        type: type,
                        url: link.href,
                        name: filename,
                        mime: mime.getType(res[1]),
                    }
                }
            }

            return {
                type: 'file',
                url: link.href,
                name: filename,
            }
        },
        hideAddress(address) {
            if (typeof address != 'string') {
                return address
            }

            return address.substr(0, 6) + "****" + address.substr(-6);
        },
        handleSearch() {
            if (this.keyword == '') {
                return
            }

            const kw = this.keyword
            this.keyword = ""
            this.showSearch = false

            if (this.provider.utils.isAddress(kw)) {
                this.$router.push('/user/profile?uid=' + kw)
                return
            }

            if (kw.length > 80) {
                this.$router.push('/tweet/detail?id=' + kw)
                return
            }

            this.$router.replace('/search?keyword=' + kw)
        },
        formatContent(str) {
            if (typeof str != 'string') {
                return str
            }

            var re = /(?:http(s)?:\/\/)+[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!%$&'*+,;=.]+/g;
            str = str.replace(re, function (website) {
                return "<a href='" + website + "' target='_blank'>" + website + "</a>";
            });

            var rm = /magnet:\?xt=urn:btih:[0-9a-fA-F]{40,}.*/g;
            str = str.replace(rm, function (res) {
                return "<a href='" + res + "'>" + res + "</a>";
            });

            return str.replace(/\n/g, '<br/>');
        },
        formatImageUrl(str) {

            if (typeof str != 'string') {
                return str
            }

            if (str.trim() == "") {
                return str.trim()
            }

            if (str.toLowerCase().startsWith('http') || str.startsWith('//')) {
                return str.replace(/http:\/\/127\S+\/ipfs\//i, this.ipfsResource)
            }

            return this.ipfsResource + str
        },
        WeiboImagesUrl(pics) {
            var arr = [];

            pics.forEach(pic => {
                arr.push(pic.replace('weibo/orj360', 'weibo/mw2000'))
            });

            return arr;
        }
    },
};