import { Http } from '@/utils/http'

export function getHome(cursor) {
    return Http({
        url: '/v0/index',
        params: { cursor: cursor }
    })
}

export function getFollowTweets(cursor) {
    return Http({
        url: '/v0/tweets/follow',
        params: { cursor: cursor }
    })
}

export function userInfo(address) {
    return Http({
        url: '/v0/users/main',
        params: { user_address: address }
    })
}

export function authorize(params) {
    return Http({
        url: '/v0/authorize',
        method: 'post',
        data: params
    })
}

export function userUpdate(params) {
    return Http({
        url: '/v0/users/update',
        method: 'post',
        data: params
    })
}

export function nonce(params) {
    return Http({
        url: '/v0/nonce',
        params: params,
    })
}

export function timeline(uid, cursor) {
    return Http({
        url: '/v0/tweets/timeline',
        params: {
            user_address: uid,
            cursor: cursor,
        },
    })
}

export function tweetInfo(id) {
    return Http({
        url: '/v0/tweets/item',
        params: {
            id: id,
        },
    })
}

export function tweetCreate(params) {
    return Http({
        url: '/v0/tweets/create',
        method: 'post',
        data: params
    })
}

export function tweetRelease(params) {
    return Http({
        url: '/v0/tweets/release',
        method: 'post',
        data: params
    })
}

export function tweetLike(twAddress) {
    return Http({
        url: '/v0/tweets/like',
        method: 'post',
        data: { tw_address: twAddress }
    })
}

export function follow(uid) {
    return Http({
        url: '/v0/users/follow',
        method: 'post',
        data: { user_address: uid }
    })
}

export function unfollow(uid) {
    return Http({
        url: '/v0/users/cancelFollow',
        method: 'post',
        data: { user_address: uid }
    })
}

export function followByPage(page) {
    return Http({
        url: '/v0/users/follow',
        params: { page: page }
    })
}

export function fansByPage(page) {
    return Http({
        url: '/v0/users/fans',
        params: { page: page }
    })
}

export function find(nickname, page) {
    return Http({
        url: '/v0/users/find',
        params: { nickname: nickname, page: page }
    })
}

export function commentRelease(twAddress, content) {
    return Http({
        url: '/v0/comment/release',
        method: 'post',
        data: { tw_address: twAddress, content: content }
    })
}

export function commentList(twAddress, page) {
    return Http({
        url: '/v0/comment/list',
        params: { tw_address: twAddress, page: page }
    })
}

export function tweetSecret(params) {
    return Http({
        url: '/v0/tweets/secret',
        method: 'post',
        data: params
    })
}